import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: JSON.parse(localStorage.getItem('userInfo')) || null,
  accessToken: localStorage.getItem('accessToken') || null,
  refreshToken: localStorage.getItem('refreshToken') || null,
};

// Check token expiration on load
const tokenExpiration = localStorage.getItem('tokenExpiration');
if (tokenExpiration) {
  const now = new Date().getTime();
  if (now > tokenExpiration) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('refreshToken');
  }
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { userData, access_token, refresh_token } = action.payload;
      const expirationTime = new Date().getTime() + 3 * 24 * 60 * 60 * 1000; // 3 days

      state.userInfo = userData || state.userInfo;
      state.accessToken = access_token || state.accessToken;
      state.refreshToken = refresh_token || state.refreshToken;

      if (userData) {
        localStorage.setItem('userInfo', JSON.stringify(userData));
      }
      if (access_token) {
        localStorage.setItem('accessToken', access_token);
        localStorage.setItem('tokenExpiration', expirationTime);
      }
      if (refresh_token) {
        localStorage.setItem('refreshToken', refresh_token);
      }
    },
    logout: (state) => {
      state.userInfo = null;
      state.accessToken = null;
      state.refreshToken = null;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('tokenExpiration');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('refreshToken');
    },
  },
});

export const { setCredentials, logout } = authSlice.actions;
export default authSlice.reducer;