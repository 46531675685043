import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLoginMutation } from '../../../redux/api/usersApiSlice';
import { setCredentials } from '../../../redux/features/auth/authSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const useLogin = ({ email, password }) => {
  const { userInfo } = useSelector((state) => state.auth);

  const [login, { isLoading }] = useLoginMutation();

  const { search } = useLocation();
  const redirect = new URLSearchParams(search).get('redirect') || '/';

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const permission = localStorage.getItem('accessToken');
    if (permission) navigate(redirect);
  }, [navigate, redirect]);

  const handleSignIn = async (event) => {
    event.preventDefault();
    console.log('Login attempt with:', { email, password });

    if (!email || !password) {
      toast.error('Please enter credentials');
      return;
    }
    try {
      const res = await login({ email, password }).unwrap();
      console.log('Login successful:', res);
      dispatch(setCredentials(res.data));
      navigate(redirect);
    } catch (error) {
      console.log('Login error:', error);
      toast.error(error.message);
    }
  };

  return {
    isLoading,
    handleSignIn,
    userInfo,
  };
};

export default useLogin;