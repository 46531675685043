import { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import navLogo from '../../resources/images/logo.png';
import useLogin from './hooks/useLogin';

export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const {
    isLoading,
    handleSignIn,
  } = useLogin({email, password});

  return (
    <div className="bg-white relative flex items-center justify-center h-screen w-screen overflow-hidden">
      <form className="flex absolute flex-col items-center justify-center shadow-md p-14 rounded-[30px]" onSubmit={handleSignIn}>
        <img src={navLogo} alt="recheix-games" className="w-[159px] h-[26px]" />
        <div className="flex flex-col items-center justify-center mt-11">
          <p className=" text-[36px] ">Sign In</p>
          <p className=" text-[16px] mt-4 text-[#666666] ">Monitor Adventures</p>
          <div className="border-[1px] rounded-lg p-3 mt-6 w-[280px] sm:w-[300px] md:w-[393px] ">
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full outline-none bg-white"
              placeholder="Email"
              type="email"
            />
          </div>
          <div className="border-[1px] rounded-lg p-3 mt-6 w-[280px] sm:w-[300px] md:w-[393px] ">
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full outline-none bg-white"
              placeholder="Password"
              type="password"
              id="password"
            />
          </div>
          <button
            className={`${isLoading ? 'p-0' : 'p-3'} bg-[#227EF3] text-[14px] text-white rounded-lg mt-6 w-full hover:bg-[#227EF399]`}
            disabled={isLoading}
          >
            {isLoading ? (
              <ClipLoader className="bg-transparent" color="#ffffff" />
            ) : (
              'Sign In'
            )}
          </button>
          <div className="mt-4 flex gap-2">
            <p className="text-[12px]">Don't have an account yet?</p>
            <p
              // onClick={() => navigate('/register')}
              className="text-[12px] text-[#227EF3] cursor-pointer"
            >
              Contact Administrator
            </p>
          </div>
        </div>
      </form>
      <div className="h-[600px] w-[600px] rounded-[70px] bg-[#4285F410]  rotate-45 ml-[0px] -mt-[200px] flex justify-center items-end ">
        <div className="h-[600px] w-[600px] rounded-[60px] bg-[#4285F410] -mb-[150px] mr-[150px] "></div>
      </div>
      <div className="h-[600px] w-[600px] rounded-[70px] bg-[#4285F410]  rotate-45 ml-[850px] mt-[370px] flex justify-center items-end ">
        <div className="h-[600px]  w-[600px] rounded-[60px] bg-[#4285F410] mb-[150px] ml-[150px] "></div>
      </div>
    </div>
  );
};
