// import React from "react";
// import ReactDOM from "react-dom";
// import App from "./App";
// import { DarkModeContextProvider } from "./context/darkModeContext";
// import { Provider } from "react-redux";
// import store from './redux/store.js'

// ReactDOM.render(
//     <DarkModeContextProvider>
//       <Provider store={store} >
//         <App />
//       </Provider>
//     </DarkModeContextProvider>
//   document.getElementById("root")
// );

import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import { Provider } from "react-redux";
import store from './redux/store.js';
import { DarkModeContextProvider } from './context/darkModeContext.js';

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store} >
    <DarkModeContextProvider>
      <App />
    </DarkModeContextProvider>
  </Provider>,
);