import Home from "./pages/home/Home";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { productInputs, userInputs } from "./formSource";
import "./style/dark.scss";
import "./index.css";
import { useContext, useEffect } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { Login } from "./pages/login/Login";
import { useSelector } from "react-redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const { darkMode } = useContext(DarkModeContext);
  const { userInfo } = useSelector(state => state.auth);

  console.log('App userInfo:', userInfo);

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <AppRoutes userInfo={userInfo} />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover
          theme="light"
        />
      </BrowserRouter>
    </div>
  );
}

function AppRoutes({ userInfo }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
  }, [userInfo, navigate]);

  return (
    <Routes>
      <Route path="/">
        <Route index element={userInfo ? <Home /> : <Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/users">
          <Route index element={<List />} />
          <Route path=":userId" element={<Single />} />
          <Route path="new" element={<New inputs={userInputs} title="Add New User" />} />
        </Route>
        <Route path="products">
          <Route index element={<List />} />
          <Route path=":productId" element={<Single />} />
          <Route path="new" element={<New inputs={productInputs} title="Add New Product" />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;